import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row, Input, Alert } from "reactstrap";

//Import Image
import lightLogo from "../../../logo.svg";
import darkLogo from "../../../logo.svg";

import linkedInLogo from "../../../assets/images/social-media/linkedIn.png";
import fbLogo from "../../../assets/images/social-media/facebook.svg";
import googleLogo from "../../../assets/images/social-media/google.svg";

import signInImage from "../../../assets/images/illustrations/login-01.svg";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { validateEmail } from "../../../utils/common";

const SignIn = () => {
  document.title = "Sign In | TalentBase";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isInvalid, setIsInvalid] = useState({
    isEmailValid: undefined,
    isPasswordValid: undefined
  });
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleDismiss = () => {
    setIsOpenAlert(false);
  };

  const signIn = () => {
    setErrorMessage("");
    setIsOpenAlert(false);

    if (!validateEmail(email)) {
      setIsInvalid({
        isEmailValid: false
      });
      return;
    }

    if (email == 'talentbase@gmail.com') {
      setIsOpenSuccessAlert(true);
    }
    if (email == 'talentbaseinvalid@gmail.com') {
      setErrorMessage("Invalid email/username. Please try again.");
      setIsOpenAlert(true);
    }
    if (email == 'talentbasedisable@gmail.com') {
      setErrorMessage("Your account is currently disabled. Please contact support for assistance.");
      setIsOpenAlert(true);
    }
  }

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                height="200"
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div>
                              <img
                                src={signInImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content px-5 py-3 h-100 text-white">
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>Welcome Back !</h5>
                                <p className="text-white-70">
                                  Sign in to continue to TalentBase
                                </p>
                              </div>
                              <div className="auth-form">
                                {errorMessage && isOpenAlert && (
                                  <Alert color="danger" toggle={handleDismiss} className="mt-3 text-danger">
                                    <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                    {errorMessage}
                                  </Alert>
                                )}

                                {/* {errorMessage && isOpenAlert && (
                                  <Alert color="success" toggle={handleDismiss} className="mt-3 text-danger">
                                    <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                    {errorMessage}
                                  </Alert>
                                )} */}

                                {isOpenSuccessAlert && (
                                  <Alert color="success" toggle={handleDismiss} className="mt-3 text-success">
                                    <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                    Login Successful
                                  </Alert>
                                )}
                                <div className="mb-3">
                                  <label
                                    htmlFor="emailInput"
                                    className="form-label"
                                  >
                                    Email
                                  </label>
                                  <div className="input-group input-group--login">
                                    <span className="input-group-text border-0">
                                      <i className="uil uil-user"></i>
                                    </span>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="emailInput"
                                      placeholder="Enter your email"
                                      onChange={(e) => { setEmail(e.target.value) }}
                                      invalid={isInvalid.isEmailValid == false}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="passwordInput"
                                    className="form-label"
                                  >
                                    Password
                                  </label>
                                  <div className="input-group input-group--login">
                                    <span className="input-group-text border-0">
                                      <i className="uil uil-lock"></i>
                                    </span>
                                    <Input
                                      type="password"
                                      className="form-control"
                                      id="passwordInput"
                                      onChange={(e) => { setPassword(e.target.value) }}
                                      placeholder="Enter your password"
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="mb-4">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                    />
                                    <Link
                                      to="/resetpassword"
                                      className="float-end text-white"
                                    >
                                      Forgot Password?
                                    </Link>
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      Remember me
                                    </label>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="button"
                                    onClick={(e) => { signIn() }}
                                    className="btn btn-white btn-hover w-100"
                                  >
                                    Sign In
                                  </button>
                                </div>
                                <div className="mt-4 text-center">
                                  <p className="mb-0">or Sign In with</p>
                                </div>
                                <ul className="candidate-detail-social-menu list-inline mb-0 mt-3 d-flex justify-content-center">
                                  {/* <li className="list-inline-item">
                                    <Link
                                      to="#"
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        src={linkedInLogo}
                                        height="24"
                                        alt=""
                                      />
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link
                                      to="#"
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img src={fbLogo} height="35" alt="" />
                                    </Link>
                                  </li> */}
                                  <li className="list-inline-item">
                                    <Link
                                      to="#"
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        src={googleLogo}
                                        height="22"
                                        alt=""
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  Don't have an account ?{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    Sign Up{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
