import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

//Import Images
import JobDetailImage from "../../../assets/images/job-detail.jpg";
import jobImages2 from "../../../assets/images/featured-job/img-02.png";
import { Link } from "react-router-dom";

const JobDetailsDescription = () => {
  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden">
        <CardBody className="p-4">
          <div>
            <Row>
              <Col md={8}>
                <div className="d-flex gap-2">
                  <div>
                      <img
                        src={jobImages2}
                        alt=""
                        className="img-fluid rounded-3 rounded-3"
                      />
                  </div>
                  <div>
                    <h5 className="mb-1">Senior Tech Lead (Java) </h5>
                    <ul className="list-inline text-muted mb-0">
                      <li className="list-inline-item">
                        TalentBase Pvt Ltd
                      </li>
                      <li className="list-inline-item text-warning review-rating">
                        <span className="badge bg-warning">4.8</span>{" "}
                        <i className="mdi mdi-star align-middle"></i>
                        <i className="mdi mdi-star align-middle"></i>
                        <i className="mdi mdi-star align-middle"></i>
                        <i className="mdi mdi-star align-middle"></i>
                        <i className="mdi mdi-star-half-full align-middle"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <ul className="list-inline mb-0 text-lg-end mt-3 mt-lg-0">
                  <li className="list-inline-item">
                    <div className="favorite-icon">
                      <Link to="#">
                        <i className="uil uil-heart-alt"></i>
                      </Link>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <Row className="g-2">
              <Col lg={3}>
                <div className="border rounded-start p-3">
                  <p className="text-muted mb-0 fs-13">Experience</p>
                  <p className="fw-medium fs-15 mb-0">Minimum 1 Year</p>
                </div>
              </Col>
              <Col lg={3}>
                <div className="border p-3">
                  <p className="text-muted fs-13 mb-0">Employee type</p>
                  <p className="fw-medium mb-0">Full Time</p>
                </div>
              </Col>
              <Col lg={3}>
                <div className="border p-3">
                  <p className="text-muted fs-13 mb-0">Position</p>
                  <p className="fw-medium mb-0">Senior</p>
                </div>
              </Col>
              <Col lg={3}>
                <div className="border rounded-end p-3">
                  <p className="text-muted fs-13 mb-0">Offer Salary</p>
                  <p className="fw-medium mb-0">$2150/ Month</p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Job Description</h5>
            <div className="job-detail-desc">
              <p className="text-muted mb-0">
                We are looking for a hardworking, intellectually curious
                individual to play the role of Senior Technical Lead (UI). This
                role requires the said individual to play the role of a seasoned
                UI engineer driving the design and implementation of innovative
                technologies within a high-performance team as well as embracing
                line management responsibilities for a team of engineers.
              </p>
            </div>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Responsibilities</h5>
            <div className="job-detail-desc mt-2">
              <p className="text-muted">
                As a Product Designer, you will work within a Product Delivery
                Team fused with UX, engineering, product and data talent.
              </p>
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-circle"></i> Software Design &
                  Maintenance: Design, develop, and maintain complex, highly
                  performant, Browser-based UI applications and services using
                  industry-standard methodologies and tools.
                </li>
                <li>
                  <i className="uil uil-circle"></i> Strategic Teamwork: Work
                  with functional specialists, understand business processes and
                  translate functional specifications into technical
                  requirements.
                </li>
                <li>
                  <i className="uil uil-circle"></i> Software Development
                  Lifecycle: Engage actively from requirements gathering and
                  design to the final stages of testing and deployment, ensuring
                  solutions meet the highest standards.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Qualification </h5>
            <div className="job-detail-desc mt-2">
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-circle"></i> Bachelor’s degree in
                  Computer Science, Software Engineering, Electronics or IT
                  related field or equivalent IT related experience.
                </li>
                <li>
                  <i className="uil uil-circle"></i> Minimum of 7 years’
                  experience in Java development, with at least 2 year
                  experience in a similar capacity.
                </li>
                <li>
                  <i className="uil uil-circle"></i> Significant exposure to
                  end-to-end software development lifecycle (SDLC) process –
                  requirements analysis to post production support.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Skill & Experience</h5>
            <div className="job-details-desc">
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-circle"></i> Excellent analytical and
                  problem-solving skills
                </li>
                <li>
                  <i className="uil uil-circle"></i> Ability to convert business
                  use cases and problems into technical solutions.
                </li>
                <li>
                  <i className="uil uil-circle"></i> Sound knowledge in UI
                  design/architecture patterns.
                </li>
                <li>
                  <i className="uil uil-circle"></i> Hands on experience in
                  working on performance optimizations.
                </li>
                <li>
                  <i className="uil uil-circle"></i> Hands on experience in Java
                  multi-threading & concurrency.
                </li>
              </ul>
              <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                <span className="badge bg-primary">JAVA</span>
                <span className="badge bg-primary">SQL</span>
                <span className="badge bg-primary">ORM</span>
                <span className="badge bg-primary">REACT</span>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
