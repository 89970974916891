import React from "react";
import About from "./About";
import Section from "./Section";
import Counter from "./Counter";
import Features from "./Feature";
import Subscribe from "../../Home/subscribe/Subscribe";
import Footer from "../../Home/footer/Footer";

const AboutUs = () => {
  document.title = "About Us | TalentBase";
  return (
    <React.Fragment>
      <Section />
      <About />
      <Counter />
      <Features />
      <div className="main-content">
        <Subscribe />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AboutUs;