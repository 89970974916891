import React from "react";
import "./components/shared/i18n";
import Routes from "./Routes/index";
import {useLoaderStore} from "./stores/Loader.store";


//import Custom Style scss
import "./assets/scss/themes.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Loader = () => {
  return (
      <div id="preloader">
          <div id="status">
              <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
              </ul>
          </div>
      </div>
  );
};

function App() {  
  const { isLoading } = useLoaderStore(state => state);
  const googleId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return (
    <GoogleOAuthProvider clientId={googleId}>
      {isLoading && Loader()}
      <React.Fragment>
        <Routes />
      </React.Fragment>
    </GoogleOAuthProvider>
  );
}

export default App;