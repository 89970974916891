import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import AboutImage from "../../../assets/images/illustrations/about-us.gif";

const About = () => {
  return (
    <React.Fragment>
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={6}>
              <div className="section-title me-lg-5">
                <h6 className="sub-title">About Us</h6>
                <h2 className="title mb-4">
                  Why TalentBase?
                </h2>
                <p className="text-muted">
                  TalentBase stands out as the ideal job portal for both job seekers and employers for several compelling reasons, 
                  ensuring a seamless and efficient job search and hiring experience:
                </p>
           
                <Row mt={4} pt={2}>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted mb-0 mb-md-3">
                      <li> Extensive Job Listing</li>
                      <li> User-Friendly Interface</li>
                      <li> Advanced Search Filters</li>
                      <li> Employer Branding</li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted">
                      <li> Efficient Recruitment Process</li>
                      <li> 24/7 Support</li>
                      <li> Mobile Accessibility</li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-img mt-4 mt-lg-0">
                <img src={AboutImage} alt="" className="img-fluid rounded" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default About;