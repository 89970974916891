import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Pagination = ({ pageNo, setPageNo, pages }) => {
  return (
    <React.Fragment>
      <Row>
        <Col lg={12} className="mt-4 pt-2">
          <nav aria-label="Page navigation example">
            {pages && (
              <ul className="pagination job-pagination mb-0 justify-content-center">
                <li className={`page-item ${pageNo === 1 ? "disabled" : ""}`}>
                  <Link
                    className="page-link"
                    to="#"
                    onClick={() => setPageNo(pageNo - 1)}
                  >
                    <i className="mdi mdi-chevron-double-left fs-15"></i>
                  </Link>
                </li>
                {Array.from({ length: pages }, (_, i) => (
                  <li
                    key={i}
                    className={`page-item ${pageNo === i + 1 ? "active" : ""}`}
                  >
                    <Link
                      className="page-link"
                      to="#"
                      onClick={() => setPageNo(i + 1)}
                    >
                      {i + 1}
                    </Link>
                  </li>
                ))}
                <li
                  className={`page-item ${pageNo === pages ? "disabled" : ""}`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    onClick={() => setPageNo(pageNo + 1)}
                  >
                    <i className="mdi mdi-chevron-double-right fs-15"></i>
                  </Link>
                </li>
              </ul>
            )}
          </nav>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Pagination;
