import { create } from "zustand";

const authStore = (set, get) => ({
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn) => {
    set(state => ({
      ...state,
      isLoggedIn
    }));
  },
});

const useAuthStore = create(authStore);

export default useAuthStore;