import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Label, Row, Modal, ModalBody } from "reactstrap";

//Images Import
import jobImage1 from "../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../assets/images/featured-job/img-03.png";
import jobImage4 from "../../assets/images/featured-job/img-04.png";
import jobImage5 from "../../assets/images/featured-job/img-05.png";
import jobImage6 from "../../assets/images/featured-job/img-06.png";
import jobImage7 from "../../assets/images/featured-job/img-07.png";

const CourseList = () => {
  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  const jobVacancyList = [
    {
      id: 1,
      companyImg: jobImage1,
      jobDescription: "Product Director",
      companyName: "Creative Agency",
      location: " Escondido,California",
      jobPostTime: "3 min ago",
      fullTime: true,
      timing: "Full Time",
      addclassNameBookmark: false,
      badges: [],
      experience: "2 - 3 years",
      salary: "$500/Month",
      matchingPercentage: 80,
      jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
    },
    {
      id: 2,
      companyImg: jobImage2,
      jobDescription: "Digital Marketing Manager",
      companyName: "TalentBase Technology Pvt.Ltd",
      location: "Phoenix, Arizona",
      jobPostTime: "15 min ago",
      fullTime: true,
      timing: "Full Time",
      catogary: "Recent Jobs",
      addclassNameBookmark: true,
      badges: [
        {
          id: 1,
          badgeclassName: "bg-warning-subtle text-warning",
          badgeName: "Urgent"
        },
        {
          id: 2,
          badgeclassName: "bg-primary-subtle text-primary",
          badgeName: "Freelance"
        }
      ],
      experience: "4+ years",
      salary: "$800/Month",
      matchingPercentage: 60,
      jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
    },
    {
      id: 3,
      companyImg: jobImage3,
      jobDescription: "Product Director",
      companyName: "Creative Agency",
      location: " Escondido,California",
      jobPostTime: "37 min ago",
      fullTime: true,
      timing: "Full Time",
      addclassNameBookmark: false,
      badges: [],
      experience: "2 - 3 years",
      salary: "$500/Month",
      matchingPercentage: 80,
      jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
    },
    {
      id: 4,
      companyImg: jobImage4,
      jobDescription: "Product Director",
      companyName: "Creative Agency",
      location: " Escondido,California",
      jobPostTime: "50 min ago",
      freeLance: true,
      timing: "Freelance",
      addclassNameBookmark: false,
      badges: [],
      experience: "2 - 3 years",
      salary: "$500/Month",
      matchingPercentage: 75,
      jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
    },
    {
      id: 5,
      companyImg: jobImage5,
      jobDescription: "Product Director",
      companyName: "Creative Agency",
      location: " Escondido,California",
      jobPostTime: "1 month ago",
      partTime: true,
      timing: "Part Time",
      addclassNameBookmark: true,
      badges: [],
      experience: "2 - 3 years",
      salary: "$1500/Month",
      matchingPercentage: 85,
      jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
    },
    {
      id: 6,
      companyImg: jobImage6,
      jobDescription: "Product Director",
      companyName: "Creative Agency",
      location: "Escondido, California",
      jobPostTime: "2 month ago",
      freeLance: true,
      timing: "Freelance",
      addclassNameBookmark: false,
      badges: [
        {
          id: 1,
          badgeclassName: "bg-warning-subtle text-warning",
          badgeName: "Urgent"
        }
      ],
      experience: "2-3 years",
      salary: "$700/Month",
      matchingPercentage: 80,
      jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
    },
    {
      id: 7,
      companyImg: jobImage7,
      jobDescription: "Product Director",
      companyName: "Creative Agency",
      location: "Escondido, California",
      jobPostTime: "2 month ago",
      partTime: true,
      timing: "Part Time",
      addclassNameBookmark: false,
      badges: [
        {
          id: 1,
          badgeclassName: "bg-warning-subtle text-warning",
          badgeName: "Urgent"
        }
      ],
      experience: "2-3 years",
      salary: "$800/Month",
      matchingPercentage: 90,
      jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
    },
    {
      id: 8,
      companyImg: jobImage3,
      jobDescription: "Product Director",
      companyName: "Creative Agency",
      location: "Escondido, California",
      jobPostTime: "3 month ago",
      internship: true,
      timing: "Internship",
      addclassNameBookmark: false,
      badges: [
        {
          id: 1,
          badgeclassName: "bg-warning-subtle text-warning",
          badgeName: "Private"
        }
      ],
      experience: "2-3 years",
      salary: "$1200/Month",
      matchingPercentage: 55,
      jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
    }
  ];
  return (
    <React.Fragment>
      <div>
        {jobVacancyList.map((jobVacancyListDetails, key) => (
          <div
            key={key}
            className={
              jobVacancyListDetails.addclassNameBookmark === true
                ? "job-box bookmark-post card mt-4"
                : "job-box card mt-4"
            }
          >
            <div className="bookmark-label text-center">
              <Link to="#" className="align-middle text-white">
                <span className="fs-8">Urgent</span>
              </Link>
            </div>
            <div className="px-5 py-4">
              <Row>
                <Col lg={1}>
                  <Link to="/companydetails">
                    <img
                      src={jobVacancyListDetails.companyImg}
                      alt=""
                      className="img-fluid rounded-3"
                    />
                  </Link>
                </Col>
                <Col lg={11}>
                  <div className="mt-3 mt-lg-0">
                    <h5 className="fs-17 mb-1">
                      <Link to="/jobdetails" className="text-dark">
                        {jobVacancyListDetails.jobDescription}
                      </Link>{" "}
                    </h5>
                    <div className="d-md-flex justify-content-between">
                      <div>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <p className="text-muted fs-14 mb-0">
                              {jobVacancyListDetails.companyName}
                            </p>
                          </li>
                          <li className="list-inline-item">
                            <p className="text-muted fs-12 mb-0">
                              <i className="mdi mdi-map-marker"></i>{" "}
                              {jobVacancyListDetails.location}
                            </p>
                          </li>
                        </ul>
                      </div>

                      <div className="d-flex flex-wrap align-items-start gap-1">
                        <span className="badge bg-primary-subtle text-primary">
                          <i className="uil uil-money-bill"></i>{" "}
                          {jobVacancyListDetails.salary}
                        </span>
                        <span className="badge bg-success-subtle text-success">
                          <i className="uil uil-briefcase"></i>{" "}
                          {jobVacancyListDetails.experience}
                        </span>
                        <span className="badge bg-danger-subtle text-danger">
                          <i className="uil uil-check-circle"></i> Matching
                          Percentage: {jobVacancyListDetails.matchingPercentage}
                          %
                        </span>
                      </div>
                    </div>
                    <div className="my-2">
                      <p className="text-truncate-3 text-muted fs-12 mb-0">
                        {jobVacancyListDetails.jobLongDescription}
                      </p>
                    </div>
                    <div className="mt-2 d-flex flex-wrap align-items-center gap-1">
                      <span
                        className={
                          jobVacancyListDetails.fullTime === true
                            ? "badge bg-success-subtle text-success fs-13 mt-1"
                            : jobVacancyListDetails.partTime === true
                            ? "badge bg-danger-subtle text-danger fs-13 mt-1"
                            : jobVacancyListDetails.freeLance === true
                            ? "badge bg-primary-subtle text-primary fs-13 mt-1"
                            : jobVacancyListDetails.internship === true
                            ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                            : ""
                        }
                      >
                        {jobVacancyListDetails.timing}
                      </span>
                      {(jobVacancyListDetails.badges || []).map(
                        (badgeInner, key) => (
                          <span
                            className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                            key={key}
                          >
                            {badgeInner.badgeName}
                          </span>
                        )
                      )}
                      <span className="text-muted fs-12 mb-0 ms-2">
                        <i className="mdi mdi-history"></i>{" "}
                        {jobVacancyListDetails.jobPostTime}
                      </span>
                    </div>
                    <div className="text-end">
                      <a href="#applyNow" onClick={openModal} className="btn btn-sm btn-primary">
                        Apply Now <i className="uil uil-angle-right-b"></i>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="favorite-icon">
                <Link to="#">
                  <i className="uil uil-heart-alt fs-18"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Apply For This Job
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="nameControlInput" className="form-label">
                    Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailControlInput2" className="form-label">
                    Email Address
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailControlInput2"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Message
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    Resume Upload
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Send Application
                </button>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseList;