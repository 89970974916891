import { create } from "zustand";

const courseGridStore = (set, get) => ({
  isGrid: false,
  setIsGrid: (isGrid) => {
    set(state => ({
      ...state,
      isGrid
    }));
  },
});

const useCourseGridStore = create(courseGridStore);

export default useCourseGridStore;