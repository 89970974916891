import React from "react";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {

  const footer = [
    {
      id: 1,
      title: <Trans i18nKey="footer.talentbase">Talentbase</Trans>,
      menu: [
        {
          id: 1,
          link: "/signUp",
          subTitle: <Trans i18nKey="footer.register">Register</Trans>
        },
        {
          id: 2,
          link: "/",
          subTitle: <Trans i18nKey="footer.jobListings">Job Listings</Trans>
        },
        {
          id: 3,
          link: "/",
          subTitle: <Trans i18nKey="footer.customerStories">Customer Stories</Trans>
        },
        {
          id: 4,
          link: "/",
          subTitle: <Trans i18nKey="footer.courses">Courses</Trans>
        },
        {
          id: 5,
          link: "/",
          subTitle: <Trans i18nKey="footer.pricing">Pricing</Trans>
        }
      ]
    },
    {
      id: 2,
      title: <Trans i18nKey="footer.support">Support</Trans>,
      menu: [
        {
          id: 1,
          link: "/contact",
          subTitle: <Trans i18nKey="footer.contactUs">Contact Us</Trans>
        },
        {
          id: 2,
          link: "/faqs",
          subTitle: <Trans i18nKey="footer.faqs">FAQ's</Trans>
        },
        {
          id: 3,
          link: "/",
          subTitle: <Trans i18nKey="footer.privacyPolicy">Privacy Policy</Trans>
        }
      ]
    }
  ];
  const footerIcons = [
    {
      id: 1,
      socialIcon: "uil uil-facebook-f"
    },
    {
      id: 2,
      socialIcon: "uil uil-linkedin-alt"
    },
    {
      id: 3,
      socialIcon: "uil uil-google"
    },
    {
      id: 4,
      socialIcon: "uil uil-twitter"
    }
  ];
  return (
    <React.Fragment>
      <section className="bg-footer">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                <h4 className="text-white mb-4"><Trans i18nKey="footer.talentbase">Talentbase</Trans></h4>
                <p className="text-white-50">
                  <Trans i18nKey="footer.yourTrustedPlatform">Your trusted platform for connecting job seekers with top employers.</Trans>
                </p>
                <p className="text-white mt-3"><Trans i18nKey="footer.followUsOn">Follow Us on</Trans>:</p>
                <ul className="footer-social-menu list-inline mb-0">
                  {footerIcons.map((footerIcondetails, key) => (
                    <li className="list-inline-item" key={key}>
                      <Link to="#">
                        <i className={footerIcondetails.socialIcon}></i>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            {footer.map((footerdetails, key) => (
              <Col lg={4} xs={6} key={key}>
                <div className="footer-item mt-4 mt-lg-0">
                  <p className="fs-16 text-white mb-4">{footerdetails.title}</p>
                  {(footerdetails.menu || []).map((menuInner, key) => (
                    <ul className="list-unstyled footer-list mb-0" key={key}>
                      <li>
                        <Link to={menuInner.link}>
                          <i className="mdi mdi-chevron-right"></i>{" "}
                          {menuInner.subTitle}
                        </Link>
                      </li>
                    </ul>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="footer-alt">
        <Container>
          <Row>
            <Col lg={12}>
              <p className="text-white-50 text-center mb-0">
                <Trans i18nKey="footer.createdWithLove">Created with love by Talentbase</Trans> &copy; {new Date().getFullYear()} &copy; | <Trans i18nKey="footer.allRightsReserved">All Rights Reserved</Trans>.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
