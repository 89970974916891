const baseUrl = process.env.REACT_APP_API_HOST;

export const UserApiEndPoints = {
  SignUp: baseUrl + "/api/signup/",
  SignIn: baseUrl + "/api/signin/",
  PasswordReset: baseUrl + "/api/password-reset/",
  Profile: baseUrl + "/api/profile/",
};

export const JobApiEndPoints = {
  autoCompleteOccupation: baseUrl + "/api/auto-complete-occupation/",
  autoCompleteLocation: baseUrl + "/api/auto-complete-location/",
  autoCompleteTitle: baseUrl + "/api/auto-complete-title/",
  jobListings: baseUrl + "/api/jobs/",
  jobList: baseUrl + "/api/jobs_list/",
};

export const IndustryApiEndPoints = {
  industryList: baseUrl + "/api/industries/",
};
