import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import useCourseGridStore from "../../stores/Courses/CourseGrid.store";
import CountryOptions from "../Common/SubSection/CountryOptions";
import JobType from "../Common/SubSection/JobType";

const CourseHeader = () => {
    const { isGrid, setIsGrid } = useCourseGridStore(state => state);
    
    return (
        <React.Fragment>
            <form action="#">
                <Row className="g-2">
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-briefcase-alt"></i>
                            <Input
                                type="search"
                                className="form-control filter-input-box"
                                id="exampleFormControlInput1"
                                placeholder="Keywords, Job title, Company"
                                style={{ marginTop: "-10px" }}
                            />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-location-point"></i>
                            <CountryOptions />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-clipboard-notes"></i>
                            <JobType />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link to="#" className="btn btn-primary w-100">
                            <i className="uil uil-search"></i> Search
                        </Link>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link onClick={(e) => setIsGrid(false)} className="btn btn-primary w-100">
                            <i className="uil uil-list-ul"></i> List view
                        </Link>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link onClick={(e) => setIsGrid(true)} className="btn btn-primary w-100 opacity-75">
                            <i className="uil uil-apps"></i> Grid view
                        </Link>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default CourseHeader;