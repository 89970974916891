import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { getIndustryList } from "../../services/Industry.service";
import { Icon } from "@iconify/react";

const CategoriesGrid = () => {
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getIndustryList();

      setIndustries(data);
    };

    fetchData();
  }, []);

  console.log(industries);

  if (!industries.length) {
    return (
      <div className="text-center mt-4">
        <p>No industries found</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Row>
        {(industries || []).map((item, key) => (
          <Col lg={3} md={6} mt={4} pt={2} key={key}>
            <div className="popu-category-box rounded text-center">
              <div className="popu-category-icon icons-md">
                <Icon icon="uim-bag" className="text-primary" />
              </div>
              <div className="popu-category-content mt-4">
                <Link
                  to={`/job-categories/${item.id}`}
                  className="text-dark stretched-link"
                >
                  <h5 className="fs-18">{item.name}</h5>
                </Link>
                <p className="text-muted mb-0">{item.job} Jobs</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default CategoriesGrid;
