import React, { Suspense } from "react";
import { userRoutes, authRoutes } from "./allRoutes";
import { Route, Routes } from "react-router-dom";

/* Layout */
const NavBarLayout = React.lazy(() => import('../components/layout/NavBarLayout'));
const AuthLayout = React.lazy(() => import('../components/layout/AuthLayout'))

const Loader = () => {
    return (
        <div id="preloader">
            <div id="status">
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    );
};

const Index = () => {
    document.body.setAttribute("data-theme", "orange");
    document.body.setAttribute("data-theme", "orange");
    return (
        <React.Fragment>
            <Suspense fallback={Loader()}>
                <Routes>
                    <Route>
                        {authRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={<AuthLayout>{route.component}</AuthLayout>}
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>

                    <Route>
                        {userRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={<NavBarLayout>{route.component}</NavBarLayout>}
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>
                </Routes>
            </Suspense>
        </React.Fragment>
    );
};

export default Index;