import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutChart = ({ series, labels }) => {

    const data = {
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            labels: labels,
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                position: 'right',
                offsetY: 0,
                height: 230,
            }
        },


    };

    return (
        <React.Fragment>
            <div>
                <ReactApexChart options={data.options} series={series} type="donut" width={380} />

            </div>
        </React.Fragment>
    );
}

export default DonutChart;