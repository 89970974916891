import React from "react";
import { Container } from "reactstrap";
import Section from "./Section";
import CategoriesGrid from "./CategoriesGrid";

const JobCategories = () => {
  document.title = "Job Categories |  TalentBase";

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <CategoriesGrid />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobCategories;
