import React, { useState } from "react";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  Form,
  NavItem,
  CardBody,
  Label,
} from "reactstrap";

import classnames from "classnames";

// {
//   "id":26,
//   "skills":[],
//   "address":"dd",
//   "city":"ddd",
//   "current_major":"",
//   "email":"dilshan@mathru.lk",
//   "first_name":"ddd",
//   "last_name":"dd",
//   "institution_user_id":"",
//   "invitation_date":null,
//   "is_invited":false,
//   "last_login":null,
//   "phone_number":"66666",
//   "postal_code":"",
//   "resume_approval_status":"pending",
//   "resume_feedback":"",
//   "state":"555555",
//   "subdomain":"",
//   "user_role":"",
//   "summary":"rrrrrrrrrrr",
//   "objectives":"rrrrrrrrrrrrrrrr",
//   "facebook_link":null,
//   "twitter_link":null,
//   "linkedin_link":null,
//   "whatsapp_link":null,
//   "profile_image":"/media/profiles/b791a3de79e74e6e87622486be941604/download.jpeg",
//   "resume":"/media/cvs/04762b510a044589be2504d11bcbc901/RD-User_Management-_v3-270924-071613.pdf",
//   "User":27,
//   "assessment":null,
//   "current_school":null,
//   "favorites":null,
//   "skills_assessment":null,
//   "recommended_jobs":[

//   ],
//   "education":[
//      {
//         "id":15,
//         "institution_name":"",
//         "degree":"",
//         "field_of_study":"",
//         "start_date":null,
//         "end_date":null,
//         "jobseeker":26
//      }
//   ],
//   "experience":[
//      {
//         "company":null,
//         "job_title":"",
//         "start_date":null,
//         "end_date":null,
//         "description":""
//      }
//   ]
// }

import userImage2 from "../../assets/images/user/img-02.jpg";
import { Link } from "react-router-dom";

const RightSideContent = ({ profileData }) => {
  const [activeTab, setActiveTab] = useState("1");

  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  tabChange("1");
                }}
                type="button"
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  tabChange("2");
                }}
                type="button"
              >
                Settings
              </NavLink>
            </NavItem>
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div>
                  <h5 className="fs-18 fw-bold">Summery</h5>
                  <p className="text-muted mt-4">{profileData?.summary}</p>
                </div>
                <div className="mt-4">
                  <h5 className="fs-18 fw-bold">Career Objectives</h5>
                  <p className="text-muted mt-4">{profileData?.objectives}</p>
                </div>

                <div className="candidate-education-details mt-4">
                  <h6 className="fs-18 fw-bold mb-0">Education</h6>
                  {profileData?.education.map((edu, index) => (
                    <div
                      className="candidate-education-content mt-4 d-flex"
                      key={index}
                    >
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        {" "}
                        {edu.degree.charAt(0)}{" "}
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1">{edu.degree}</h6>
                        <p className="mb-2 text-muted">
                          {edu.institution_name} - ({edu.start_date} -{" "}
                          {edu.end_date})
                        </p>
                        <p className="text-muted">{edu.field_of_study}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="candidate-education-details mt-4">
                  <h6 className="fs-18 fw-bold mb-0">Experiences</h6>

                  {profileData?.experience.map((exp, index) => (
                    <div
                      className="candidate-education-content mt-4 d-flex"
                      key={index}
                    >
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        {" "}
                        {exp.job_title.charAt(0)}{" "}
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1">{exp.job_title}</h6>
                        <p className="mb-2 text-muted">
                          {exp.company} - ({exp.start_date} - {exp.end_date})
                        </p>
                        <p className="text-muted">{exp.description}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="my-4">
                  <h5 className="fs-18 fw-bold">Skills and Expertise</h5>
                </div>
                <div className="mt-0">
                  <div className="d-flex justify-content-between gap-2 max-w-20r">
                    <span className="fs-14">Cloud Management</span>
                    <ul className="list-inline d-flex justify-content-center align-items-center min-w-100">
                      <li className="list-inline-item text-warning fs-19">
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star-half-full"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-between gap-2 max-w-20r">
                    <span className="fs-14">AWS</span>
                    <ul className="list-inline d-flex justify-content-center align-items-center min-w-100">
                      <li className="list-inline-item text-warning fs-19">
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star-half-full"></i>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mt-4">
                  <h5 className="fs-18 fw-bold">Spoken languages</h5>
                </div>
                <div className="mt-0 d-flex flex-wrap align-items-start gap-1">
                  <span className="badge fs-13 bg-success-subtle text-success mt-2">
                    English
                  </span>
                  <span className="badge fs-13 bg-success-subtle text-success mt-2">
                    German
                  </span>
                  <span className="badge fs-13 bg-success-subtle text-success mt-2">
                    French
                  </span>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <Form action="#">
                  <div>
                    <h5 className="fs-17 fw-semibold mb-3 mb-0">My Account</h5>
                    <div>
                      <h6 className="fs-15 fw-semibold mb-3 mb-0">
                        Attachments
                      </h6>
                      <div className="d-flex flex-column gap-2 mb-3">
                        <div>
                          <Label htmlFor="resume" className="form-label">
                            Resume
                          </Label>
                          <Input
                            id="resume"
                            type="file"
                            className="profile-img-file-input"
                          />
                        </div>
                        <div>
                          <div className="fs-14 mb-2 mb-0"></div>
                          <Label htmlFor="coverLetter" className="form-label">
                            Cover Letter
                          </Label>
                          <Input
                            id="coverLetter"
                            type="file"
                            className="profile-img-file-input"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div>
                      <h6 className="fs-15 fw-semibold mb-3 mb-0">Profile</h6>
                      <div className="d-flex flex-column gap-3 mb-3">
                        <div className="mb-2">
                          <Label htmlFor="Summery" className="form-label">
                            Summery
                          </Label>
                          <textarea
                            className="form-control"
                            placeholder="Enter your summery"
                            name="Summery"
                            rows="3"
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="Objectives" className="form-label">
                            Objectives
                          </Label>
                          <textarea
                            className="form-control"
                            placeholder="Enter your objectives"
                            name="Objectives"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div>
                      <h6 className="fs-15 fw-semibold mb-3 mb-0">
                        Basic Information
                      </h6>
                      <div className="text-center">
                        <div className="mb-4 profile-user">
                          <img
                            src={userImage2}
                            className="rounded-circle img-thumbnail profile-img"
                            id="profile-img"
                            alt=""
                          />
                          <div className="p-0 rounded-circle profile-photo-edit">
                            <Input
                              id="profile-img-file-input"
                              type="file"
                              className="profile-img-file-input"
                            />
                            <Label
                              htmlFor="profile-img-file-input"
                              className="profile-photo-edit avatar-xs"
                            >
                              <i className="uil uil-edit cursor-pointer"></i>
                            </Label>
                          </div>
                        </div>
                      </div>

                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">
                              First Name
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={profileData?.first_name}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="lastName" className="form-label">
                              Last Name
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="lastName"
                              value={profileData?.last_name}
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              for="nameControlInput"
                              className="form-label"
                            >
                              Contact Number
                            </Label>
                            <div className="row">
                              <div className="col-3">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nameControlInput"
                                  placeholder="(+94)"
                                  onChange={(e) => {
                                    setCountryCode(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-9">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nameControlInput"
                                  placeholder="Phone number"
                                  onChange={(e) => {
                                    setPhonenumber(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="email" className="form-label">
                              Email
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="email"
                              value={profileData?.email}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="permanentAddress"
                              className="form-label"
                            >
                              Permanent Address
                            </Label>
                            <div className="d-flex flex-column gap-1">
                              <Input
                                type="text"
                                className="form-control"
                                id="permanentAddress"
                                placeholder="Address Line 01"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="permanentAddressL2"
                                placeholder="Address Line 02"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="pCity"
                                placeholder="City"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="pState"
                                placeholder="State"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="pZipcode"
                                placeholder="Zip Code"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="pCountry"
                                placeholder="Country"
                                value={profileData?.address}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="permanentAddress"
                              className="form-label"
                            >
                              Current Address
                            </Label>
                            <div className="d-flex flex-column gap-1">
                              <Input
                                type="text"
                                className="form-control"
                                id="currentAddress"
                                placeholder="Address Line 01"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="currentAddressL2"
                                placeholder="Address Line 02"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="cCity"
                                placeholder="City"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="cState"
                                placeholder="State"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="cZipcode"
                                placeholder="Zip Code"
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="cCountry"
                                placeholder="Country"
                                value={profileData?.address}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              for="nameControlInput"
                              className="form-label"
                            >
                              Date Of Birth
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="nameControlInput"
                              placeholder="21 Aug 2001"
                              onChange={(e) => {
                                setDateOfBirth(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="languages" className="form-label">
                              Languages
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="languages"
                              value={profileData?.email}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">Social Media</h5>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="facebook" className="form-label">
                            Facebook
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="facebook"
                            to="https://www.facebook.com"
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="twitter" className="form-label">
                            Twitter
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="twitter"
                            to="https://www.twitter.com"
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="linkedin" className="form-label">
                            Linkedin
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="linkedin"
                            to="https://www.linkedin.com"
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="whatsapp" className="form-label">
                            Whatsapp
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="whatsapp"
                            to="https://www.whatsapp.com"
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">Education</h5>
                    <hr />
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">Work Experience</h5>
                    <hr />
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">
                      Skills and Expertise
                    </h5>
                    <hr />
                  </div>

                  <div className="mt-4 text-end">
                    <Link to="#" className="btn btn-primary">
                      Update
                    </Link>
                  </div>
                </Form>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
