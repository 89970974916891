import { IndustryApiEndPoints } from "../constants/Api";
import { baseApi } from "./Base.api";

export async function getIndustryList() {
  try {
    const response = await baseApi.get(IndustryApiEndPoints.industryList);

    return response;
  } catch (error) {
    throw error;
  }
}
