import axios from "axios";
import { UserApiEndPoints } from '../constants/Api';
import { baseApi } from './Base.api';

export async function updateProfile(data) {
    try {
        const response = await baseApi.post(UserApiEndPoints.Profile, data);

        return response;
    } catch (error) {
        throw error;
    }
}

export async function getProfile(data) {
    try {
        const response = await baseApi.get(UserApiEndPoints.Profile);

        return response;
    } catch (error) {
        throw error;
    }
}

export async function passwordReset(data) {
    try {
        const response = await baseApi.apiPostWithoutToken(UserApiEndPoints.PasswordReset, data);

        return response;
    } catch (error) {
        throw error;
    }
}

export async function signup(data) {
    try {
        const response = await baseApi.apiPostWithoutToken(UserApiEndPoints.SignUp, data);

        return response;
    } catch (error) {
        throw error;
    }
}

export async function signin(data) {
    try {
        const response = await baseApi.apiPostWithoutToken(UserApiEndPoints.SignIn, data);

        return response;
    } catch (error) {
        throw error;
    }
}

export async function getGoogleUserData() {
    return new Promise((resolve, reject) => {
        axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${getAccessToken()}`, {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function setLoginType(loginType) {
    localStorage.setItem('loginType', loginType);
};

export function setAccessToken(token) {
    localStorage.setItem('accessToken', token);
};

export function getAccessToken() {
    return localStorage.getItem('accessToken');
};

export function setRefreshToken(token) {
    localStorage.setItem('refreshToken', token);
};

export function setProfileData(data) {
    localStorage.setItem('profileData', data);
};

export function getRefreshToken() {
    return localStorage.getItem('refreshToken');
};

export function getLoginType() {
    localStorage.getItem('loginType');
};

export function getProfileData() {
    localStorage.getItem('profileData');
};

export function logout() {
    localStorage.clear();
};