import React, { useState } from "react";
import { Input, Modal, ModalBody, Label } from "reactstrap";
import { Form } from "react-bootstrap";

const ProfileInformation = ({ modal = false, openModal, callBackSubmit }) => {

    const onSubmitEvent = (e) => {
        e.preventDefault();
        callBackSubmit()
    }

    return (
      <div className="modal-dialog modal-dialog-centered">
        <Modal isOpen={modal} toggle={openModal} centered>
          <ModalBody className="modal-body p-5" style={{ maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' }}>
            <div className="d-flex">
              <div className="modal-header-icon me-3">
                <i
                  className="uil uil-file-info-alt"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </div>
              <div className="mb-1">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Profile - Personal Information
                </h5>
                <div>
                  Improve your personal profile for free in less than 5 minutes.
                </div>
              </div>
            </div>
            <hr />
            <div className="position-absolute end-0 top-0 p-3">
              <button
                type="button"
                onClick={openModal}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <Form onSubmit={onSubmitEvent}>
              <div className="mb-4 mt-2">
                <Label className="form-label" for="inputGroupFile01">
                  Upload CV
                </Label>
                <Input
                  type="file"
                  className="form-control"
                  id="inputGroupFile01"
                />
              </div>
              <div className="mb-3">
                <Label for="emailControlInput2" className="form-label">
                  Email Address
                </Label>
                <Input
                  type="email"
                  className="form-control"
                  id="emailControlInput2"
                  placeholder="user@gmail.com"
                />
              </div>
              <div className="mb-3">
                <Label for="nameControlInput" className="form-label">
                  Full Name
                </Label>
                <div className="row">
                  <div className="col">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <Label for="nameControlInput" className="form-label">
                  Contact Number
                </Label>
                <div className="row">
                  <div className="col-3">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="(+94)"
                    />
                  </div>
                  <div className="col-9">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Phone number"
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <Label for="nameControlInput" className="form-label">
                  Date Of Birth
                </Label>
                <Input
                  type="date"
                  className="form-control"
                  id="nameControlInput"
                  placeholder="21 Aug 2001"
                />
              </div>

              <div className="mb-3">
                <Label for="nameControlInput" className="form-label">
                  Permanent Address
                </Label>
                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="Address Line 01"
                />

                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="Address Line 02"
                />

                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="City"
                />

                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="State"
                />

                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="Zip Code"
                />

                <Input
                  type="text"
                  className="form-control"
                  id="nameControlInput"
                  placeholder="Country"
                />
              </div>

              <div className="mb-3">
                <Label for="nameControlInput" className="form-label">
                  Current Address
                </Label>
                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="Address Line 01"
                />

                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="Address Line 02"
                />

                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="City"
                />

                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="State"
                />

                <Input
                  type="text"
                  className="form-control mb-2"
                  id="nameControlInput"
                  placeholder="Zip Code"
                />

                <Input
                  type="text"
                  className="form-control"
                  id="nameControlInput"
                  placeholder="Country"
                />
              </div>

              <div className="d-flex">
                <button type="submit" className="btn btn-outline-primary w-100 me-2">
                  I will add later
                </button>

                <button type="submit" className="btn btn-primary w-100">
                  Next
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
};

export default ProfileInformation;