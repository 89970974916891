import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row } from "reactstrap";
import JobType from "../Common/SubSection/JobType";
import useJobGridStore from "../../stores/JobCards/JobGrid.store";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  locationAutoComplete,
  titleAutoComplete,
} from "../../services/Job.service";

const SearchHeader = () => {
  const [country, setCountry] = useState("");
  const { isGrid, setIsGrid } = useJobGridStore((state) => state);

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let jobTitle = queryParams.get("title");
  let jobLocation = queryParams.get("location");

  const [titleInput, setTitleInput] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [titlesList, setTitlesList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    const getData = async () => {
      if (titleInput) {
        const filteredTitles = await titleAutoComplete(titleInput);
        setTitlesList(filteredTitles);
      }
      if (locationInput) {
        const filteredLocations = await locationAutoComplete(locationInput);
        setLocationsList(filteredLocations);
      }
    };

    getData();
  }, [titleInput, locationInput]);

  useEffect(() => {
    if (jobTitle) {
      setSelectedTitle(jobTitle);
    }
    if (jobLocation) {
      setSelectedLocation(jobLocation);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search/?title=${selectedTitle}&location=${selectedLocation}`);
  };

  return (
    <React.Fragment>
      <form action="#">
        <Row className="g-2">
          <Col lg={2} md={6}>
            <div className="filler-job-form relative">
              <i className="uil uil-briefcase-alt"></i>
              <Input
                type="search"
                className="form-control filter-input-box"
                id="exampleFormControlInput1"
                placeholder="Keywords, Job title, Company"
                style={{ marginTop: "-10px" }}
                value={titleInput || selectedTitle}
                onChange={(e) => setTitleInput(e.target.value)}
              />
              {titlesList.length > 0 && titleInput && (
                <div className="auto-complete-box">
                  {titlesList.map((title, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedTitle(title);
                        setTitleInput("");
                      }}
                    >
                      {title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Col>
          <Col lg={2} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-location-point"></i>
              <Input
                type="search"
                className="form-control filter-input-box"
                id="exampleFormControlInput1"
                placeholder="Colombo"
                style={{ marginTop: "-10px" }}
                value={locationInput || selectedLocation}
                onChange={(e) => setLocationInput(e.target.value)}
              />
              {locationsList.length > 0 && locationInput && (
                <div className="auto-complete-box">
                  {locationsList.map((location, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedLocation(location);
                        setLocationInput("");
                      }}
                    >
                      {location}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Col>
          <Col lg={2} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-clipboard-notes"></i>
              <JobType />
            </div>
          </Col>
          <Col lg={2} md={6}>
            <Button onClick={handleSubmit} className="btn btn-primary w-100">
              <i className="uil uil-search"></i> Search
            </Button>
          </Col>
          <Col lg={2} md={6}>
            <Button
              onClick={(e) => setIsGrid(false)}
              className={"btn btn-primary w-100 " + (!isGrid && " opacity-75")}
            >
              <i className="uil uil-list-ul"></i> List view
            </Button>
          </Col>
          <Col lg={2} md={6}>
            <Button
              onClick={(e) => setIsGrid(true)}
              className={"btn btn-primary w-100 " + (isGrid && " opacity-75")}
            >
              <i className="uil uil-apps"></i> Grid view
            </Button>
          </Col>
        </Row>
        <Row className="g-2 mt-1">
          <Col lg={3} md={6}>
            <Link className="btn btn-primary w-100">
              <i className="uil uil-filter"></i> Advanced filters
            </Link>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default SearchHeader;
