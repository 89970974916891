import React, { useState } from "react";
import { Input, Modal, ModalBody, Label } from "reactstrap";

const ProfileIndustry = ({ modal = false, openModal, callBackSubmit }) => {
    const [industries, setIndustries] = useState([]);
    const [currentIndustry, setCurrentIndustry] = useState([]);

    const onSubmitEvent = (e) => {
        e.preventDefault();
        callBackSubmit()
    }

    const addIndustry = () => {
        setIndustries([...industries, currentIndustry]);
    }

    return (
      <div className="modal-dialog modal-dialog-centered">
        <Modal isOpen={modal} toggle={openModal} centered>
          <ModalBody className="modal-body p-5">
            <div className="text-center mb-4">
              <h5 className="modal-title" id="staticBackdropLabel">
                Profile - Industries
              </h5>
              <div>Select your Industries</div>
            </div>
            <div className="position-absolute end-0 top-0 p-3">
              <button
                type="button"
                onClick={openModal}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="mb-3">
              <Label for="nameControlInput" className="form-label">
                Industries
              </Label>
              <div className="d-flex">
                <div className="flex-grow-1 me-2">
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Industry 01"
                    onChange={(e) => setCurrentIndustry(e.target.value)}
                  />
                </div>
                <div style={{ width: "25%" }}>
                  <button
                    type="button"
                    onClick={addIndustry}
                    className="btn btn-primary w-100"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            {/* <div class="d-flex flex-wrap align-items-start gap-1 mb-3">
              <span class="badge rounded-pill bg-primary">Primary</span>
              <span class="badge rounded-pill bg-primary">Primary</span>
              <span class="badge rounded-pill bg-primary">Primary</span>
            </div> */}

            <div class="d-flex flex-wrap align-items-start gap-1 mb-3">
              {industries &&
                industries.length > 0 &&
                industries.map((data) => {
                  return  <span class="badge rounded-pill bg-primary">{data}</span>;
                })}
            </div>

            <div className="d-flex gap-2">
              <button type="button" className="btn btn-outline-primary w-100">
                I will add later
              </button>

              <button
                type="button"
                onClick={onSubmitEvent}
                className="btn btn-primary w-100"
              >
                Save
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
};

export default ProfileIndustry;