import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Label, Row, Modal, ModalBody } from "reactstrap";

const JobsList = ({ jobs }) => {
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  if (!jobs || jobs.length === 0) {
    return (
      <div className="text-center mt-4">
        <p>No job listings found</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        {jobs.map((jobsDetails, key) => (
          <div
            key={key}
            className={
              jobsDetails.addclassNameBookmark === true
                ? "job-box bookmark-post card mt-4"
                : "job-box card mt-4"
            }
          >
            <div className="bookmark-label text-center">
              <Link to="#" className="align-middle text-white">
                <span className="fs-8">Urgent</span>
              </Link>
            </div>
            <div className="px-5 py-4">
              <Row>
                <Col lg={1}>
                  <Link to={`/job-details/${jobsDetails.id}`}>
                    <img
                      src={jobsDetails.companyImg}
                      alt=""
                      className="img-fluid rounded-3"
                    />
                  </Link>
                </Col>
                <Col lg={11}>
                  <div className="mt-3 mt-lg-0">
                    <h5 className="fs-17 mb-1">
                      <Link
                        to={`/job-details/${jobsDetails.id}`}
                        className="text-dark"
                      >
                        {jobsDetails.title}
                      </Link>{" "}
                    </h5>
                    <Link to={`/job-details/${jobsDetails.id}`}>
                      <div className="d-md-flex justify-content-between">
                        <div>
                          <ul className="list-inline mb-0">
                            <li className="">
                              <p className="text-muted fs-14 mb-0">
                                {jobsDetails.company}
                              </p>
                            </li>
                            <li className="">
                              <p className="text-muted fs-12 mb-0">
                                <i className="mdi mdi-map-marker"></i>{" "}
                                {jobsDetails.location}
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="d-flex flex-wrap align-items-start gap-1">
                          <span className="badge bg-primary-subtle text-primary">
                            <i className="uil uil-money-bill"></i>{" "}
                            {jobsDetails.salary}
                          </span>
                          <span className="badge bg-success-subtle text-success">
                            <i className="uil uil-briefcase"></i>{" "}
                            {jobsDetails.experience_needed}
                          </span>
                          <span className="badge bg-danger-subtle text-danger">
                            <i className="uil uil-check-circle"></i> Matching
                            Percentage: {jobsDetails.matchingPercentage}%
                          </span>
                        </div>
                      </div>
                      <div className="my-2">
                        <p className="text-truncate-3 text-muted fs-12 mb-0">
                          {jobsDetails.description}
                        </p>
                      </div>
                      <div className="mt-2 d-flex flex-wrap align-items-center gap-1">
                        <span
                          className={
                            jobsDetails.job_type === "Full Time"
                              ? "badge bg-success-subtle text-success fs-13 mt-1"
                              : jobsDetails.job_type === "Part Time"
                              ? "badge bg-danger-subtle text-danger fs-13 mt-1"
                              : jobsDetails.job_type === "Freelance"
                              ? "badge bg-primary-subtle text-primary fs-13 mt-1"
                              : jobsDetails.job_type === "Internship"
                              ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                              : ""
                          }
                        >
                          {jobsDetails.job_type}
                        </span>
                        {(jobsDetails.badges || []).map((badgeInner, key) => (
                          <span
                            className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                            key={key}
                          >
                            {badgeInner.badgeName}
                          </span>
                        ))}
                        <span className="text-muted fs-12 mb-0 ms-2">
                          <i className="mdi mdi-history"></i>{" "}
                          {jobsDetails.time_since}
                        </span>
                      </div>
                    </Link>
                    <div className="text-end">
                      <a
                        href="#applyNow"
                        onClick={openModal}
                        className="btn btn-sm btn-primary"
                      >
                        Apply Now <i className="uil uil-angle-right-b"></i>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="favorite-icon">
                <Link to="#">
                  <i className="uil uil-heart-alt fs-18"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Apply For This Job
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="nameControlInput" className="form-label">
                    Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailControlInput2" className="form-label">
                    Email Address
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailControlInput2"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Message
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    Resume Upload
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Send Application
                </button>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobsList;
