import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { setAccessToken, setLoginType, getGoogleUserData, setProfileData } from "../../../services/User.service";
import useAuthStore from "../../../stores/Auth/Auth.store";

const AuthSuccessGoogle = () => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {isLoggedIn, setIsLoggedIn} = useAuthStore(state => state);

    useEffect(() => {
        let token = searchParams.get("token");
        setLoginType('google');
        setAccessToken(token);
        setIsLoggedIn(true);
        navigate('/profile');
    }, []);

    return (
        <React.Fragment>
            <div>
                redirecting
            </div>
        </React.Fragment>
    );
}


export default AuthSuccessGoogle;