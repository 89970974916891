import React from "react";
import { Col, Container, Row } from "reactstrap";
import useCourseGridStore from "../../stores/Courses/CourseGrid.store";
import CourseGridHeader from "./CourseHeader";
import CourseVacancyGrid from "./CourseGrid";
import CourseVacancyList from "./CourseList";
import Section from "./Section";
import Pagination from "./Pagination";

const Courses = () => {
  const { isGrid } = useCourseGridStore(state => state);

  document.title = "Courses | TalentBase";
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <CourseGridHeader />
          <Row>
            {isGrid ? <CourseVacancyGrid /> : <CourseVacancyList />}
          </Row>
          <Pagination />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Courses;