import React from "react";
import Section from "./Section";
import FaqContent from "./FaqContent";

const Faqs = () => {
  document.title = "FAQs | TalentBase";
  return (
    <React.Fragment>
      <Section />
      <FaqContent />
    </React.Fragment>
  );
};

export default Faqs;