import { create } from "zustand";

const jobGridStore = (set, get) => ({
  isGrid: false,
  setIsGrid: (isGrid) => {
    set(state => ({
      ...state,
      isGrid
    }));
  },
});

const useJobGridStore = create(jobGridStore);

export default useJobGridStore;