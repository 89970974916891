import React from "react";
import SignIn from "../pages/Authentication/sign-in/SignIn";
import SignUp from "../pages/Authentication/sign-up/SignUp";
import ResetPassword from "../pages/Authentication/reset-password/ResetPassword";
import AboutUs from "../pages/Company/AboutUs/AboutUs";
import ContactUs from "../pages/Company/ContactUs/ContactUs";
import Faqs from "../pages/Company/FAQ/Faqs";
import Error404 from "../pages/Common/Error/Error404";
import Jobs from "../pages/Job/JobCards/Jobs";

const Home = React.lazy(() => import('../pages/Home/Home'));

const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/home", component: <Home /> },
  { path: "/aboutus", component: <AboutUs /> },
  { path: "/about", component: <AboutUs /> },
  { path: "/contact", component: <ContactUs /> },
  { path: "/faqs", component: <Faqs /> },
  { path: "/jobs", component: <Jobs /> }
];

const authRoutes = [
  { path: "/error404", component: <Error404 /> },
  { path: "/signin", component: <SignIn /> },
  { path: "/signUp", component: <SignUp /> },
  { path: "/resetpassword", component: <ResetPassword /> },
];

export { userRoutes, authRoutes };