import { JobApiEndPoints } from "../constants/Api";
import { baseApi } from "./Base.api";

export async function occupationAutoComplete() {
  try {
    const response = await baseApi.get(JobApiEndPoints.autoCompleteOccupation);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getJobListings(jobTitle, location) {
  try {
    const response = await baseApi.get(
      JobApiEndPoints.jobListings + `?q=${jobTitle}&location=${location}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getJobList() {
  try {
    const response = await baseApi.get(JobApiEndPoints.jobList);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getJobsWithPagination(pageNo) {
  try {
    const response = await baseApi.get(
      JobApiEndPoints.jobList + `?page=${pageNo}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function locationAutoComplete(query) {
  try {
    const response = await baseApi.get(
      JobApiEndPoints.autoCompleteLocation + `?search=${query}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function titleAutoComplete(query) {
  try {
    const response = await baseApi.get(
      JobApiEndPoints.autoCompleteTitle + `?search=${query}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}
