import React, { useState } from "react";
import { Input, Modal, ModalBody, Label, Button, Col, FormGroup } from "reactstrap";
import { Form } from "react-bootstrap";

const ProfileEducation = ({ modal = false, openModal, callBackSubmit }) => {
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    const years = Array.from(new Array(currentYear - startYear + 1), (val, index) => startYear + index);
    
    const [start, setStart] = useState(currentYear);
    const [end, setEnd] = useState(currentYear);
    
    const onSubmitEvent = (e) => {
        e.preventDefault();
        callBackSubmit()
       
    }

    return (
      <div className="modal-dialog modal-dialog-centered">
        <Modal isOpen={modal} toggle={openModal} centered>
          <ModalBody className="modal-body p-5">
            <div className="d-flex">
              <div className="modal-header-icon me-3">
                <i
                  className="uil uil-file-info-alt"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </div>
              <div className="mb-1">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Profile - Education
                </h5>
                <div>Add your educational details here</div>
              </div>
            </div>
            <hr />

            <div className="position-absolute end-0 top-0 p-3">
              <button
                type="button"
                onClick={openModal}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <Form onSubmit={onSubmitEvent}>
              <div className="mb-3">
                <h6 for="nameControlInput" className="form-label">
                  Education 01
                </h6>
                <FormGroup>
                  <Label for="universityInput" className="form-label">
                    University
                  </Label>
                  <Input
                    type="text"
                    className="form-control mb-2"
                    id="universityInput"
                    placeholder="University"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="universityInput" className="form-label">
                    Degree
                  </Label>
                  <Input
                    type="text"
                    className="form-control mb-2"
                    id="nameControlInput"
                    placeholder="Degree"
                  />
                </FormGroup>
                <FormGroup row className="mb-2">
                  <Label for="yearRange" className="form-label col-sm-12">
                    Period
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="select"
                      name="startYear"
                      id="startYear"
                      value={start}
                      onChange={(e) => setStart(e.target.value)}
                    >
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      type="select"
                      name="endYear"
                      id="endYear"
                      value={end}
                      onChange={(e) => setEnd(e.target.value)}
                    >
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label for="universityInput" className="form-label">
                    Description   
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Description"
                  />
                </FormGroup>
              </div>

              <div>
                <button type="submit" className="btn btn-primary w-100">
                  + Add more
                </button>
              </div>

              <hr />

              <div className="d-flex">
                <button type="submit" className="btn btn-outline-primary w-100 me-2">
                  I will add later
                </button>

                <button type="submit" className="btn btn-primary w-100">
                  Next
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
};

export default ProfileEducation;