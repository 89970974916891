import React from "react";
import { Trans, useTranslation } from 'react-i18next';
//Import Image
import lightLogo from "../../../logo.svg";
import darkLogo from "../../../logo.svg";

import resetPasswordImage from "../../../assets/images/auth/forgot-password.svg";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

const ResetPassword = () => {
  const { t } = useTranslation();
  document.title = <Trans i18nKey="resetPassword.title">Reset Password | TalentBase</Trans>;

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                        <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                height="200"
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div>
                              <img
                                src={resetPasswordImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="text-center mb-4">
                              <h5> <Trans i18nKey="resetPassword.resetPassword">Reset Password</Trans></h5>
                              <p className="text-white-50">
                                <Trans i18nKey="resetPassword.resetPasswordTalentbase">Reset your password with TalentBase</Trans>.
                              </p>
                            </div>
                            <Form className="auth-form text-white">
                              <div
                                className="alert alert-warning text-center mb-4"
                                role="alert"
                              >
                                {" "}
                                <Trans i18nKey="resetPassword.enterEmail">Enter your Email and instructions will be sent
                                  to you!</Trans>.
                                {" "}
                              </div>
                              <div className="mb-4">
                                <label className="form-label" htmlFor="email">
                                  <Trans i18nKey="resetPassword.email">Email</Trans>
                                </label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder={t("resetPassword.enterUserEmail")}
                                />
                              </div>
                              <div className="mt-3">
                                <button
                                  type="submit"
                                  className="btn btn-white w-100"
                                >
                                  <Trans i18nKey="resetPassword.sendRequest">Send Request</Trans>
                                </button>
                              </div>
                            </Form>
                            <div className="mt-5 text-center text-white-50">
                              <p>
                                <Trans i18nKey="resetPassword.rememberIt">Remembered It</Trans>?{" "}
                                <Link
                                  to="/signin"
                                  className="fw-medium text-white text-decoration-underline"
                                >
                                  {" "}
                                  <Trans i18nKey="resetPassword.goToLogin">Go to Login</Trans>{" "}
                                </Link>
                              </p>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;