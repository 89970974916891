import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import useJobGridStore from "../../../stores/JobCards/JobGrid.store";
import JobGridHeader from "./JobHeader";
import JobsGrid from "../../Common/SubSection/JobsGrid";
import JobsList from "../../Common/SubSection/JobsList";
import Section from "./Section";
import Pagination from "./Pagination";
import { getJobsWithPagination } from "../../../services/Job.service";

const Jobs = () => {
  const { isGrid } = useJobGridStore((state) => state);

  const [jobs, setJobs] = useState([]);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getJobsWithPagination(pageNo);

        console.log("Job Listings:", data);

        setJobs(data);
      } catch (error) {
        console.error("Error fetching job listings:", error);
      }
    };

    fetchData();

    console.log("Page No:", Math.ceil(jobs?.count) / 10);
  }, [pageNo]);

  document.title = "Job Cards | TalentBase";

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <JobGridHeader />
          <Row>
            {isGrid ? (
              <JobsGrid jobs={jobs?.results} />
            ) : (
              <JobsList jobs={jobs?.results} />
            )}
          </Row>
          <Pagination
            pageNo={pageNo}
            setPageNo={setPageNo}
            pages={Math.ceil(jobs?.count / 10)}
          />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Jobs;
