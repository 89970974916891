import { create } from "zustand";

export const useLoaderStore = create((set) => ({
    isLoading: false,
    setIsLoading: (isLoading) => {
        set(state => ({
            ...state,
            isLoading
        }));
    }
}));