import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import LeftSideContent from "./LeftSideContent";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import { getProfile } from "../../services/User.service";

const MyProfile = () => {
  document.title = "My Profile | TalentBase";

  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await getProfile();

        if (response.details) {
          setError(response.details);
          return;
        }

        console.log("Profile Data:", response);

        setProfileData(response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, []);

  if (error) {
    return (
      <div className="text-center mt-4">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <LeftSideContent profileData={profileData} />
            <RightSideContent profileData={profileData} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default MyProfile;
