import React from "react";
import ReactApexChart from "react-apexcharts";
import DonutChart from "./DonutChart";

const SkillComparison = () => {

    const graphData = {

        series: [{
            data: [90, 85, 81, 50, 22]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 380,
            },
            plotOptions: {
                bar: {
                    barHeight: '60%',
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom'
                    },
                }
            },
            colors: ['#EF851F', '#FA0C62', '#3767E4', '#3DC51B', '#4592FF', '#2b908f', '#f9a3a4', '#90ee7e',
                '#f48024', '#69d2e7'
            ],
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold'
                },
                formatter: function (val, opt) {
                    return opt.w.globals.labels[opt.dataPointIndex]
                },

                offsetX: 100,
                dropShadow: {
                    enabled: true
                }
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: ['Javascript', 'HTML5', 'CSS', 'SCSS', 'Angular'],
                min: 0,
                max: 100,
                labels: {
                    formatter: function (value, timestamp, opts) {
                        return `${value}%`
                    }
                }
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
            subtitle: {
                text: 'Skill percentage',
                align: 'center',
                position: 'bottom'
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            }
        },


    };


    return (
        <React.Fragment>
            <div>
                {/* Bar chart */}
                <div id="chart">
                    <ReactApexChart options={graphData.options} series={graphData.series} type="bar" height={350} />
                </div>

                {/* Donut Chart */}
                <div>
                    <div>Javascript</div>
                    <DonutChart series={[50, 25, 10, 5]} labels={['Current Skill %', 'Course Set 01', 'Course Set 02', 'Course Set 03']} />
                </div>

                <div>
                    <div>HTML 5</div>
                    <DonutChart series={[50, 25, 10, 5]} labels={['Current Skill %', 'Course Set 01', 'Course Set 02', 'Course Set 03']} />
                </div>

                <div>
                    <div>CSS</div>
                    <DonutChart series={[50, 25, 10, 5]} labels={['Current Skill %', 'Course Set 01', 'Course Set 02', 'Course Set 03']} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default SkillComparison;