import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row } from "reactstrap";
import Section from "./Section";
import SearchHeader from "./SearchHeader";
import useJobGridStore from "../../stores/JobCards/JobGrid.store";
import { getJobListings } from "../../services/Job.service";
import JobsGrid from "../Common/SubSection/JobsGrid";
import JobsList from "../Common/SubSection/JobsList";

const Jobs = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let jobTitle = queryParams.get("title");
  let jobLocation = queryParams.get("location");

  const [jobListings, setJobListings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getJobListings(jobTitle, jobLocation);

        setJobListings(data.results);
      } catch (error) {
        console.error("Error fetching job listings:", error);
      }
    };

    fetchData();
  }, [jobTitle, jobLocation]);

  const { isGrid } = useJobGridStore((state) => state);

  document.title = "Job Search | TalentBase";

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <SearchHeader />
          <Row>
            {isGrid ? (
              <JobsGrid jobs={jobListings} />
            ) : (
              <JobsList jobs={jobListings} />
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Jobs;
